<template>
	<div class="box">
		<div class="odiv">
			<div class="odiv-item">
				<el-upload class="avatar-uploader" action="" ref="upload" :show-file-list="false" list-type="picture"
					:multiple="false" :http-request="importFile" :on-change="onChange"
					accept=".jpg,.jpeg,.png,gif,JPG,JPEG,PNG,GIF">
					<img v-if="imageUrl" :src="imageUrl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<el-button style="margin-left: 120px;margin-top: 10px;" size="mini" type="primary" @click="dataFormSubmit()">确 定</el-button>
			</div>
		</div>		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imageUrl: '',
				file: ''
			};
		},
		created() {
			console.log('dddd');
		},
		methods: { //选择文件时触发
			onChange(file) {
				this.imageUrl = URL.createObjectURL(file.raw);
				// 这里做一些文件控制，注意：就算一次选取多个文件，这里依旧会执行多次
				let typeArray = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
				let isJPG = typeArray.indexOf(file.raw.type)
				if (isJPG === -1) {
					this.$message.error('上传头像图片只能是 jpg、jpeg、png或gif 格式！！！')
				}
				this.fileList = file;
				// console.log(file)
			},
			//自定义的上传方法用于覆盖自带的action上传，在这里我们只拿到要上传的文件，不提交
			importFile() {
				this.formData = new FormData()
				this.formData.append('file', this.fileList.raw, 'yingji')
			},
			//点击确定发起的提交
			dataFormSubmit() {
				if (this.fileList != null) {
					this.$apiFun.getUIpload(this.formData).then(data => {
						// console.log(data)
						if (data && data.code === 200) {
							this.$message({
								message: '上传文件成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.fileList = null
								}
							})
							this.fileList = null;
						} else {
							this.$message.error(data.data.msg)
						}
					}).catch((err) => {
						console.log(err)
						this.$message.error('上传文件异常')
					})
				} else {
					this.$message.error('请先选取上传文件')
				}
			},
			tapUpload() {
				console.log(this.file);
				const formate = new FormData();
				formate.append('file', this.file);
				this.$apiFun.getUIpload(formate).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						this.$message.success("上传成功");

					} else {
						this.$message.error(res.msg);
					}
				})
			},
			handleAvatarSuccess(res, file) {
				console.log(res, file);
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				console.log(file, 'ee');
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				if (isLt2M) {
					this.imageUrl = URL.createObjectURL(file);
					this.file = file
				}
				return false;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
<style scoped>
	.odiv-item {
		margin: 20px;
	}

	.odiv {
		width: 100%;
		margin: 15px;
		background-color: #fff;
	}

	.box {
		width: 100%;
		background-color: rgb(235, 235, 235);
	}


	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>