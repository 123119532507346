import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import index from '../views/index.vue';
import manage from '../views/manage.vue';
import LinkManagement from '../components/LinkManagement.vue';
import userClickList from '../views/userClickList.vue';
import addAdminLink from '../views/addAdminLink.vue';
import userLinkList from '../views/userLinkList.vue';
import courseList from '../views/courseList.vue';
import onLineStudentList from '../views/onLineStudentList.vue';
import outLineStudentList from '../views/outLineStudentList.vue';
import unUiupload from '../views/unUiupload.vue';
import getPayList from '../views/getPayList.vue';
import getPayItem from '../views/getPayItem.vue';
import getAdd from '../page/qrCode/getAdd.vue';
import getDel from '../page/qrCode/getDel.vue';
import getUser from '../page/addUser.vue';
Vue.use(VueRouter)

const routes = [{
		path: '/Login',
		component: Login
	},
	{
		path: '/index',
		component: index,
		children: [{
				path: '',
				component: LinkManagement
			}, {
				path: 'manage',
				component: manage
			}, {
				path: 'LinkManagement',
				component: LinkManagement

			}, {
				path: 'userClickList',
				component: userClickList

			},
			{
				path: 'addAdminLink',
				component: addAdminLink

			},
			{
				path: 'userLinkList',
				component: userLinkList

			}, {
				path: 'courseList',
				component: courseList

			}, {
				path: 'onLineStudentList',
				component: onLineStudentList

			}, {
				path: 'unUiupload',
				component: unUiupload
			}, {
				path: 'outLineStudentList',
				component: outLineStudentList

			},
			{
				path: 'getPayItem',
				component: getPayItem

			},
			{
				path: 'getAdd',
				component: getAdd

			},
			{
				path: 'getDel',
				component: getDel

			},
			{
				path: 'getUser',
				component: getUser
			}
		]
	},
	{
		path: '/getPayList',
		component: getPayList
	},
	{
		path: "",
		component: Login
	}
]

const router = new VueRouter({
	routes
});

export default router