<template>
	<div class="max">
		<img class="title" src="../assets/title.png" />
		<div class="content">
			<img src="../assets/Login.png" />
			<div class="box">
				<div class="box-item">
					<el-input placeholder="登陆用户名" prefix-icon="el-icon-user" v-model="username">
					</el-input>
				</div>
				<div class="box-item">
					<el-input placeholder="登录密码" show-password prefix-icon="el-icon-lock" v-model="passWord">
					</el-input>
				</div>
				<div class="box-btn">
					<el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				username: null,
				passWord: null
			}
		},
		methods: {
			submitForm: function() {
				let that = this
				this.$apiFun.postLogin({
					username: this.username,
					password: this.passWord
				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						that.$store.commit("setToken", res.data.token)
						that.$store.commit("setId", res.data.id);
						that.$store.commit("setType", res.data.type);
						this.$router.push("/index/LinkManagement");
					} else {
						this.$message.error(res.msg);
					}
				})

			}
		}
	}
</script>

<style scoped>
	.title {
		display: block;
		margin: 28px auto;
		width: 195px;
		height: 39px;
	}

	.content img {
		width: 320px;
		height: 282px;
		margin-right: 158px;
	}

	.el-button {
		padding: 12px 126px !important;
	}

	.box-btn {
		width: 100%;
	}

	.box-item {
		width: 280px;
		margin-bottom: 20px;
	}

	.content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 50px 0;
		background-color: #e0f1ff;
	}

	.max {
		width: 100%;
	}
</style>
