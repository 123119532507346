<template>
	<div class="content">
		<div class="box">
			<el-button @click="tapResetting()" type="primary">返回</el-button>

	<!-- 		<div class="box-item">
				<div style="width: 300px;">
					<el-input v-model="input" placeholder="请输入链接进行查询"></el-input>
				</div>

				<el-button @click="taplinkId()" type="primary">查询</el-button>

				<div style="width: 300px;margin-left: 80px;">
					<el-input v-model="input1" placeholder="请输入微信昵称查询"></el-input>
				</div>

				<el-button @click="tapNickname()" type="primary">查询</el-button>

				<el-button @click="tapId()" type="primary" style="margin-left: 100px;">查询全部</el-button>
			</div> -->
		</div>

		<el-table :row-class-name="tableRowClassName" @row-click="onRowClick" :data="gridData" :stripe="true"
			height="84vh" :border="true">
			<el-table-column property="" label="头像">
				<template slot-scope="scope">
					<img v-if="scope.row.payType==2" class="userImg" src="../assets/icon.png" />
					<img v-else class="userImg" :src="scope.row.headUrl" />
				</template>
			</el-table-column>
			<el-table-column property="userPhone" label="手机号"></el-table-column>
			<el-table-column property="userName" label="昵称"></el-table-column>
			<el-table-column property="ordernumber" label="订单号"></el-table-column>
			<el-table-column property="timesClickIndex" label="点击报名">
			</el-table-column>
			<el-table-column property="timesClickCashier" label="待支付">
			</el-table-column>
			<el-table-column property="timesClickPay" label="点击支付">
			</el-table-column>
			<!-- <el-table-column property="userUid" label="uid"></el-table-column> -->
			<el-table-column property="createTime" width="160" label="创建时间"></el-table-column>
			<el-table-column property="payTime" width="160" label="支付时间"></el-table-column>
			<el-table-column property="payType" label="支付类型" :formatter="appPayType"></el-table-column>
			<el-table-column property="money" label="支付金额"></el-table-column>
			<el-table-column property="status" label="是否支付" :formatter="appStatus"></el-table-column>
		</el-table>
		<div class="el-pagination">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" :current-page.sync="currentPage"
				background layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		data() {
			return {
				gridData: [],
				currentPage: 1, //初始页
				pagesize: 20, //    每页的数据
				total: 0, //    总数
				userList: [],
				row_index: '',
				tableData: [],
				dialogVisible: false,
				input: '',
				input1: '',
				id: '',
				nickname: ''
			}
		},
		created() {
			this.id = this.$route.query.id
			this.getUserList()
		},
		methods: {
			tapNickname() { // 微信
				if (this.input1.length > 0) {
					this.id = ''
					this.nickname = this.input1
					this.getUserList()
				} else {
					this.$message.error({
						message: '输入不能为空!',
						duration: 500
					});
				}
			},
			taplinkId() { // 连接
				if (this.input.length > 0) {
					this.nickname = ''
					this.id = this.input
					this.getUserList()
				} else {
					this.$message.error({
						message: '输入不能为空!',
						duration: 500
					});
				}
			},
			tapId() {
				this.id = ''
				this.nickname = ''
				this.getUserList()
			},
			appPayType(row, column) {
				let value = row[column.property];
				if (value == "2") return "支付宝";
				else if (value == "3") return "微信";
				else return "";
			},
			appStatus(row, column) {
				let value = row[column.property];
				if (value == "0") return "未支付";
				else if (value == "1") return "已支付";
				else if (value == "3") return "支付中";
				else if (value == "4") return "支付失败";
				else return "";
			},
			tapResetting() {
				this.$router.push("/index/userClickList");
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getUserList()
			},
			appCreateTime(row) {
				return row.createTime.substring(0, 10)
			},
			appActive(row) {
				return row.status == -1 ? '已删除' : '正常'
			},
			appType(row, column) {
				return this.formatDate(row[column.property] * 1000)
			},
			appSex(row, column) {
				let value = row[column.property];
				if (value == "0") return "男";
				else if (value == "1") return "女";
				else return "";
			},
			submitForm(formName) {
				console.log(this.ruleForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.postSaveOrUpdateUser()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			formatDate(date, fmt) {
				date = new Date(date)
				if (typeof fmt === 'undefined') {
					fmt = 'yyyy-MM-dd HH:mm:ss'
				}
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
				}
				const o = {
					Y: date.getFullYear(),
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'H+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds(),
				}
				for (const k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						const str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
					}
				}
				return fmt
			},
			getUserList() {
				this.$apiFun.getPayList({
					linkId: this.id,
					pageSize: this.pagesize,
					pageNum: this.currentPage,
					nickname: this.nickname
				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						this.gridData = res.data.list
						this.total = res.data.total
						this.$message.success({
							message: '操作成功!',
							duration: 500
						});
					} else {
						this.$message.error(res.msg);
					}
				})
				// this.$apiFun.getCourseList({
				// 	startTime: "",
				// 	endTime: "",
				// 	pageSize: this.pagesize,
				// 	pageNum: this.currentPage
				// }).then(res => { //eslint-disable-line no-unused-vars
				// 	this.tableData = res.data
				// 	this.total = res.total
				// }).catch(error => {
				// 	alert(error)
				// })
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				row.row_index = rowIndex;
			},
			onRowClick(row) {
				this.row_index = row.row_index
			},
			tapUpdate() {
				// this.$apiFun.postSaveOrUpdateUser({ //id 为空在添加，不为空则更新
				// 	username: this.tableData[this.row_index].username, //登陆用户名
				// 	password: this.tableData[this.row_index].password, //密码
				// 	sex: this.tableData[this.row_index].sex, //0:男性 1：女性
				// 	type: this.tableData[this.row_index], //0:班主任 1：管理员
				// 	note: this.tableData[this.row_index], //备注
				// 	active: this.tableData[this.row_index], //1 激活状态  0：冻结状态不能登陆
				// 	name: this.tableData[this.row_index], //真实姓名
				// 	phone: this.tableData[this.row_index] //手机号
				// }).then(res => { //eslint-disable-line no-unused-vars
				// 	if (res.code == 200) {
				// 		this.$message.success("操作成功");
				// 		this.dialogVisible = false
				// 		this.getUserList()
				// 	} else {
				// 		this.$message.error(res.msg);
				// 	}
				// })
			}
		}

	}
</script>

<style scoped>
	.box-item el-button {
		margin-left: 50px;
	}

	.box-item div {
		margin-right: 10px;
	}

	.box-item {
		display: flex;
	}

	.box {
		display: flex;
		justify-content: space-between;
		padding: 20px;
	}

	.userImg {
		width: 50px;
		height: 50px;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	.el-pagination {
		width: 100%;
		background-color: #ffffff;
		padding: 15px 0;
		display: flex;
		justify-content: flex-end;
		margin-right: 40px;
	}

	.box {
		background-color: #ffffff;

	}

	.content {
		width: 98%;
		margin: 0 auto;
		background-color: #ededed;
	}
</style>