<template>
	<div class="content">
		<div class="box">
			<!-- <el-button @click="dialogVisible = true" type="primary">添加用户</el-button> -->
		</div>
		<div class="odiv">
			<el-select v-model="activeName" placeholder="请选择" @change="handleClick($event)">
				<el-option v-for="item in this.tableDatas" :key="item.admin_key" :label="item.course_name"
					:value="item.admin_key">
				</el-option>
			</el-select>
		</div>
		
		
		<el-table :row-class-name="tableRowClassName" @row-click="onRowClick" :data="tableData" :stripe="true"
			style="width: 100%" height="80vh" :border="true">
			<!-- <el-table-column type="index" width="50">
			</el-table-column> -->

			<el-table-column prop="course_id" label="课程ID">
			</el-table-column>

			<el-table-column prop="name" label="用户名">
			</el-table-column>

			<el-table-column prop="uid" label="uid">
			</el-table-column>

			<el-table-column prop="join_time" label="进入房间时间">
			</el-table-column>

			<el-table-column prop="leave_time" label="离开房间时间">
			</el-table-column>

			<el-table-column prop="vtype" label="种类" :formatter="appPayStatus">
			</el-table-column>

			<el-table-column prop="duration_time" label="用户停留时间">
			</el-table-column>

		</el-table>
		<div class="el-pagination">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" :current-page.sync="currentPage"
				background layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		data() {
			return {
				activeName: '',
				currentPage: 1, //初始页
				pagesize: 20, //    每页的数据
				currentPages: 1, //初始页
				pagesizes: 20, //    每页的数据
				total: 0, //    总数
				userList: [],
				row_index: '',
				tableData: [],
				tableDatas: [],
				dialogVisible: false,
				ruleForm: {
					id: '',
					username: '',
					password: '',
					sex: '',
					type: '',
					note: '',
					active: '',
					name: '',
					phone: ''
				}

			}
		},
		created() {
			this.getCourseList()
		},
		methods: {
			handleCurrentChange(val) {
				this.currentPage = val
				this.getUserList()
			},
			getCourseList() {
				this.$apiFun.getCourseList({
					startTime: "",
					endTime: "",
					pageSize: this.pagesizes,
					pageNum: this.currentPages
				}).then(res => { //eslint-disable-line no-unused-vars
					this.tableDatas = res.data
						this.activeName = res.data[0].course_name
					this.getUserList()
				}).catch(error => {
					alert(error)
				})
			},
			appPayStatus(row, column) {
				let value = row[column.property];
				if (value == "1") return "直播";
				else if (value == "2") return "回放";
				else return "";
			},
		handleClick(event) {
			this.tableDatas.forEach(item => {
				if (item.admin_key == event) {
					console.log(item.course_id)
					this.getUserList(item.course_id)
				}
			})
		
		
		
		
		
			// this.$apiFun.getOnLineStudentList({
			// 	startTime: "",
			// 	endTime: "",
			// 	pageSize: this.pagesize,
			// 	pageNum: this.currentPage,
			// 	course_id: this.tableDatas[tab.index].course_id
			// }).then(res => { //eslint-disable-line no-unused-vars
			// 	this.tableData = res.data
			// 	this.total = parseInt(res.total)
			// }).catch(error => {
			// 	alert(error)
			// })
		},
			appCreateTime(row) {
				return row.createTime.substring(0, 10)
			},
			appActive(row) {
				return row.status == -1 ? '已删除' : '正常'
			},
			appType(row, column) {
				return this.formatDate(row[column.property] * 1000)
			},
			appSex(row, column) {
				let value = row[column.property];
				if (value == "0") return "男";
				else if (value == "1") return "女";
				else return "";
			},
			submitForm(formName) {
				console.log(this.ruleForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.getOutLineStudentListt()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getUserList(val) {
				this.$apiFun.getOutLineStudentListt({
					startTime: "",
					endTime: "",
					pageSize: this.pagesize,
					pageNum: this.currentPage,
					course_id: val || this.tableDatas[0].course_id
				}).then(res => { //eslint-disable-line no-unused-vars
					this.tableData = res.data
					this.total = parseInt(res.total)
				}).catch(error => {
					alert(error)
				})
			},

			tableRowClassName({
				row,
				rowIndex
			}) {
				row.row_index = rowIndex;
			},
			onRowClick(row) {
				this.row_index = row.row_index
			}
		}

	}
</script>

<style scoped>
	.odiv{
		background-color: #ffffff;
		padding: 15px;
	}
	/deep/ .el-tabs__header {
		margin: 0;
	}

	.el-pagination {
		width: 100%;
		background-color: #ffffff;
		padding: 15px 0;
		display: flex;
		justify-content: flex-end;
		margin-right: 40px;
	}

	.box {
		background-color: #ffffff;

	}

	.content {
		width: 100%;
		padding: 15px;
		background-color: #ededed;
	}
</style>
