<template>
	<div class="box">
		<el-col :span="12">
			<el-menu :default-active="$route.path" class="el-menu-vertical-demo" background-color="#545c64"
				text-color="#fff" active-text-color="#ffd04b">

				<router-link tag="span" to="/index/LinkManagement">
					<el-menu-item index="/index/LinkManagement">
						<i class="el-icon-menu"></i>
						<span slot="title">动态支付短链接</span>
					</el-menu-item>
				</router-link>

				<router-link tag="span" to="/index/manage" v-show="this.$store.getters.getType!=0">
					<el-menu-item index="/index/manage">
						<i class="el-icon-menu"></i>
						<span slot="title">用户管理</span>
					</el-menu-item>
				</router-link>

				<router-link tag="span" to="/index/userClickList">
					<el-menu-item ref="elMenu" :index="nihao">
						<i class="el-icon-menu"></i>
						<span slot="title"> 短连接列表</span>
					</el-menu-item>
				</router-link>

				<router-link tag="span" to="/index/addAdminLink">
					<el-menu-item index="/index/addAdminLink">
						<i class="el-icon-menu"></i>
						<span slot="title">固定支付短链接</span>
					</el-menu-item>
				</router-link>

				<!-- <router-link tag="span" to="/index/userLinkList">
					<el-menu-item index="/index/userLinkList">
						<i class="el-icon-menu"></i>
						<span slot="title">用户支付连接</span>
					</el-menu-item>
				</router-link> -->

				<router-link tag="span" to="/index/courseList">
					<el-menu-item index="/index/courseList">
						<i class="el-icon-menu"></i>
						<span slot="title">课程列表</span>
					</el-menu-item>
				</router-link>

				<router-link tag="span" to="/index/onLineStudentList">
					<el-menu-item index="/index/onLineStudentList">
						<i class="el-icon-menu"></i>
						<span slot="title">直播访客列表</span>
					</el-menu-item>
				</router-link>

				<router-link tag="span" to="/index/outLineStudentList">
					<el-menu-item index="/index/outLineStudentList">
						<i class="el-icon-menu"></i>
						<span slot="title">回放访客列表</span>
					</el-menu-item>
				</router-link>

				<router-link tag="span" to="/index/getPayItem">
					<el-menu-item index="/index/getPayItem">
						<i class="el-icon-menu"></i>
						<span slot="title">短连接支付详情</span>
					</el-menu-item>
				</router-link>


				<router-link tag="span" to="/index/getAdd" v-show="this.$store.getters.getType!=0">
					<el-menu-item index="/index/getAdd">
						<i class="el-icon-menu"></i>
						<span slot="title">活码</span>
					</el-menu-item>
				</router-link>
				
				<router-link tag="span" to="/index/unUiupload" v-show="this.$store.getters.getType!=0">
					<el-menu-item index="/index/unUiupload">
						<i class="el-icon-menu"></i>
						<span slot="title">小程序二维码</span>
					</el-menu-item>
				</router-link>
				
				<router-link tag="span" to="/index/getUser" v-show="this.$store.getters.getType!=0">
					<el-menu-item index="/index/getUser">
						<i class="el-icon-menu"></i>
						<span slot="title">添加用户信息</span>
					</el-menu-item>
				</router-link>


			<!-- 	<el-submenu index="1">
					<template slot="title">
						<i class="el-icon-location"></i>
						<span>活码</span>
					</template>
					<el-menu-item-group>
						<router-link tag="span" to="/index/getAdd">
							<el-menu-item index="/index/getAdd">添加活码</el-menu-item>
						</router-link>
						<router-link tag="span" to="/index/getdel">
							<el-menu-item index="/index/getdel">删除活码</el-menu-item>
						</router-link>
					</el-menu-item-group>
				</el-submenu> -->

			</el-menu>
		</el-col>
		<router-view></router-view>
		<!-- 退出登录 -->
		<div class="logout">
			<el-button type="primary" @click="logout">退出登录</el-button>
		</div>
	</div>
</template>


<script>
	export default {
		name: 'App',
		components: {},
		data() {
			return {
				nihao: "/index/userClickList"
			}
		},
		created() {
			const hasLogin = localStorage.getItem('token');
			if (!hasLogin) {
				this.$router.push('/login')
			}
			console.log(this.$store.getters)
		},
		watch: {
			// 取消导航栏的选中状态
			$route: {
				handler(val, oldval) {

					if (oldval.fullPath == "/index/userClickList") {
						this.nihao = "/index/getPayList"
						console.log(this.nihao)
					}
					console.log(val); //新路由信息
					console.log(oldval); //老路由信息
					console.log(this.$refs.elMenu.closeMenu)
					console.log(this.$refs.elMenu.activeIndex);
					// if(val.name=='smViewer'){
					//    this.$refs.elMenu.closeMenu(this.$refs.elMenu.openedMenus[0]);
					//    this.$refs.elMenu.activeIndex = null
					// }
				},
				// 深度观察监听
				deep: true
			}
		},
		methods: {
			logout() {
				localStorage.removeItem('token');
				this.$router.push('/login')
			}
		}
	}
</script>

<style scoped>
	.logout {
		position: fixed;
		left: 20px;
		bottom: 20px;
	}

	.box {
		width: 100%;
		display: flex;
	}

	.hello {
		width: 175px;
	}

	.el-col-12 {
		width: 175px !important;
		min-width: 175px !important;
		max-width: 175px !important;
	}

	.el-menu-item {
		min-width: 175px !important;
	}

	.el-submenu {
		min-width: 175px !important;
	}
</style>