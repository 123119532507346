<template>
	<div class="box">
		<div class="odiv">
			<div class="item">
				<div class="item-inp">
					<el-input v-model="input1" placeholder="请输入身份证号码"></el-input>
					<el-input v-model="input2" placeholder="请输入手机号"></el-input>
				</div>
				<div class="btn">
					<el-radio v-model="radio" label="报名中">报名中</el-radio>
					<el-radio v-model="radio" label="报名成功">报名成功</el-radio>
					<el-radio v-model="radio" label="考试中">考试中</el-radio>
					<el-radio v-model="radio" label="邮寄中">邮寄中</el-radio>
					<el-radio v-model="radio" label="订单完结">订单完结</el-radio>
					<div class="butt">
						<el-button type="primary" @click="postAdd()">提交</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				radio: '报名中',
				input1: '',
				input2: ''
			};
		},
		created() {
			console.log('dddd');
		},
		methods: {

			postAdd() {
				this.$apiFun.postAddRegistorStatus({
					idcard: this.input1,
					mobile: this.input2,		
					status: this.radio
				}).then(res => {
					if (res.code == 200) {
						this.$message.success("提交成功");
						setTimeout(()=>{
							// this.refresh()
						},1000)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			refresh(){
				location.reload()
			}
		}
	}
</script>


<style>
	.butt {
		margin-top: 30px;
		display: flex;
		justify-content: center;
	}

	.btn {}

	.item-inp input {
		margin-bottom: 24px;
	}

	.item-inp {
		width: 260px;
		margin: 0 auto;
	}

	.item {
		margin: 4%;
		width: 510px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
<style scoped>
	.odiv-item {
		margin: 20px;
	}

	.odiv {
		width: 100%;
		margin: 15px;
		background-color: #fff;
		/* border: 1px solid red; */
	}

	.box {
		width: 100%;
		background-color: rgb(235, 235, 235);
	}


	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>