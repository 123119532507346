<template>
	<div class="content">
		<div class="active">
			<div class="box">
				<div class="card" v-for="(item,index) in list" :key="index">
					<div class="card-box">
						<div class="card-box-text">二维码图片{{index+1}}</div>
						<img class="card-box-img" :src="item.path" />
					</div>
					<div class="card-box">
						<div class="card-box-text">权重:</div>
						<el-input @input="handleChange(index)" v-model="item.weight"
							placeholder="请输入权重(1-10)  默认为1"></el-input>
					</div>
					<el-button type="primary" @click="DeleteQrcode(item.id,index)">删除</el-button>
				</div>
			</div>
			<div class="Qrcode" v-show="Qrcode">
				<img class="" :src="Qrcode" />
			</div>
			<div class="btn">
				<el-upload class="avatar-uploader" action="" ref="upload" :show-file-list="false" list-type="picture"
					:multiple="false" :http-request="importFile" :on-change="onChange"
					accept=".jpg,.jpeg,.png,gif,JPG,JPEG,PNG,GIF">
					<el-button type="primary">添加活码</el-button>
				</el-upload>
				<el-button @click="PostSaveQrcode()" type="primary">生成活码</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imageUrl: '',
				file: '',
				dialogVisible: true,
				list: [],
				Qrcode: ''
			};
		},
		created() {
			this.getAllQrcode()
		},
		methods: { //选择文件时触发
			onChange(file) {
				console.log(1)
				this.imageUrl = URL.createObjectURL(file.raw);
				// 这里做一些文件控制，注意：就算一次选取多个文件，这里依旧会执行多次
				let typeArray = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
				let isJPG = typeArray.indexOf(file.raw.type)
				if (isJPG === -1) {
					this.$message.error('上传头像图片只能是 jpg、jpeg、png或gif 格式！！！')
				}
				this.fileList = file;
				setTimeout(() => (
					this.dataFormSubmit()
				), 1000)
			},
			//自定义的上传方法用于覆盖自带的action上传，在这里我们只拿到要上传的文件，不提交
			importFile() {
				this.formData = new FormData()
				this.formData.append('file', this.fileList.raw, 'yingji')
			},
			//点击确定发起的提交
			dataFormSubmit() {
				if (this.fileList != null) {
					this.$apiFun.uploadQrcode(this.formData).then(data => {
						// console.log(data)
						if (data && data.code === 200) {
							this.$message({
								message: '上传文件成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.fileList = null
								}
							})
							console.log(data.data)
							this.list.push({
								path: data.data.path,
								name: data.data.name
							})
							this.fileList = null;
						} else {
							this.$message.error(data.data.msg)
						}
					}).catch((err) => {
						console.log(err)
						this.$message.error('上传文件异常')
					})
				} else {
					this.$message.error('请先选取上传文件')
				}
				console.log(3)
			},
			tapUpload() {
				console.log(this.file);
				const formate = new FormData();
				formate.append('file', this.file);
				this.$apiFun.getUIpload(formate).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						this.$message.success("上传成功");
					} else {
						this.$message.error(res.msg);
					}
				})
				console.log(4)
			},
			handleAvatarSuccess(res, file) {
				console.log(res, file);
				this.imageUrl = URL.createObjectURL(file.raw);
				console.log(5)
			},
			beforeAvatarUpload(file) {
				console.log(6)
				console.log(file, 'ee');
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				if (isLt2M) {
					this.imageUrl = URL.createObjectURL(file);
					this.file = file
				}
				return false;
			},
			getAllQrcode() {
				this.$apiFun.getAllQrcode({

				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						this.list = res.data.qrCodes
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			PostSaveQrcode() {
				this.$apiFun.postSaveQrcode({
					qrCodes: this.list
				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						console.log(res.data)
						this.Qrcode = res.data
						this.$message.success("生成成功");
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			DeleteQrcode(e, k) {
				if (e == undefined) {
					this.list.splice(k, 1);
					this.$message.success("删除成功");
					return
				}
				this.$apiFun.postDeleteQrcode({
					id: e
				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						console.log(res)
						this.$message.success("删除成功");
						this.getAllQrcode()
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			handleChange(e) {
				console.log(111111);
				// 通过正则表达式判断输入是否符合要求
				if (/^(0|[1-9][0-9]*)$/.test(this.list[e].weight)) {
					console.log(222);
					// 转换为整数并限制范围为1-100
					let num = parseInt(this.list[e].weight)
					if (num < 0) {
						num = 0
					} else if (num > 10) {
						num = 10
					}
					this.list[e].weight = num.toString()
				} else {
					console.log(33);
					// 不符合要求则清空输入框
					this.list[e].weight = ''
				}
			}
		}
	}
</script>

<style>
	.el-button {
		height: 40px;
	}

	.btn .avatar-uploader .el-upload {
		border: 0;
	}

	.avatar-uploader {
		margin-right: 10px;
	}
</style>
<style scoped>
	.Qrcode img {
		width: 200px;
		height: 200px;
	}

	.Qrcode {
		width: 93%;
		display: flex;
		justify-content: end;
	}

	.btn {
		width: 90%;
		margin: 0 auto;
		display: flex;
		justify-content: end;
		text-align: end;
		padding-top: 30px;
		padding-right: 96px;
		padding-bottom: 30px;
	}

	.card-box-img {
		width: 100px;
		height: 100px;
		margin-left: 30px;
	}

	.card-box-text {
		width: 50px;
		margin-right: 6px;
	}

	.card-box-text {
		width: 90px;
	}

	.card-box {
		display: flex;
		align-items: center;
		margin-right: 40px;
	}

	.card {
		display: flex;
		align-items: center;
		padding-top: 40px;
	}

	.active {
		width: 100%;
		background-color: #fff;
		margin: 15px;
	}

	.box {
		width: 84.5%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.content {
		width: 100%;
		display: flex;
		background-color: rgb(235, 235, 235);
	}
</style>