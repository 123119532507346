<template>
	<div class="content">
		<div class="box">
			<el-button  @click="addUser" type="primary">添加用户</el-button>
		</div>
		<el-table :row-class-name="tableRowClassName" @row-click="onRowClick" :data="tableData" :stripe="true"
			style="width: 100%" height="80vh"  :border="true">
			<!-- <el-table-column type="index" width="50">
			</el-table-column> -->
			<el-table-column prop="username" label="用户名">
			</el-table-column>
			<el-table-column prop="type" label="类型" :formatter="appType">
			</el-table-column>
			<el-table-column prop="active" label="状态" :formatter="appActive">
			</el-table-column>
			<el-table-column prop="sex" label="性别" :formatter="appSex">
			</el-table-column>
			<el-table-column prop="name" label="姓名">
			</el-table-column>
			<el-table-column prop="phone" label="手机号">
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间" :formatter="appCreateTime">
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
				<el-button @click="tapUpdate(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
				<el-button @click="deleteUser(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
			</template>
			</el-table-column>
		</el-table>
		<el-dialog :visible.sync="dialogVisible" width="30%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<!-- <el-form-item label="用户id" prop="name">
					<el-input v-model="ruleForm.id"></el-input>
				</el-form-item> -->
				<el-form-item label="登陆用户名" prop="username">
					<el-input v-model="ruleForm.username"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password" v-if="!ruleForm.id">
					<el-input v-model="ruleForm.password"></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="sex">
					<el-radio-group v-model="ruleForm.sex">
						<el-radio :label="0">男</el-radio>
						<el-radio :label="1">女</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="账户类别" prop="type">
					<el-radio-group v-model="ruleForm.type">
						<el-radio label="0">班主任</el-radio>
						<el-radio label="1">管理员</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input v-model="ruleForm.note"></el-input>
				</el-form-item>
				<el-form-item label="激活状态" prop="active">
					<el-radio-group v-model="ruleForm.active">
						<el-radio :label="1">激活</el-radio>
						<el-radio :label="0">冻结</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="真实姓名" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="ruleForm.phone"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">确 定
				</el-button>
			</span>
		</el-dialog>
		<div class="el-pagination">
			<el-pagination 
      @current-change="handleCurrentChange"
			:page-size="pagesize"
      :current-page.sync="currentPage" background layout="prev, pager, next" :total="total" >
			</el-pagination>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'App',
		components: {},
		data() {
			return {
				currentPage: 1, //初始页
				pagesize: 20, //    每页的数据
				total: 0, //    总数
				userList: [],
				row_index: '',
				tableData: [],
				dialogVisible: false,
				ruleForm: {
					id: '',
					username: '',
					password: '',
					sex: '',
					type: '',
					note: '',
					active: '',
					name: '',
					phone: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请至少选择一个账户类别',
						trigger: 'change'
					}]
				}
			}
		},
		created() {
			this.getUserList()
		},
		methods: {
			handleCurrentChange(val){
				this.currentPage = val
				this.getUserList()
			},
			appCreateTime(row) {
				return row.createTime.substring(0, 10)
			},
			appActive(row, column) {
				let value = row[column.property];
				if (value == "0") return "冻结";
				else if (value == "1") return "激活";
				else return "";
			},
			appType(row, column) {
				let value = row[column.property];
				if (value == "0") return "班主任";
				else if (value == "1") return "管理员";
				else return "";
			},
			appSex(row, column) {
				let value = row[column.property];
				if (value == "0") return "男";
				else if (value == "1") return "女";
				else return "";
			},
			addUser() {
				this.dialogVisible = true
				this.ruleForm.id = ''
				this.$refs.ruleForm?.resetFields()
			},
			submitForm(formName) {
				console.log(this.ruleForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.postSaveOrUpdateUser()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			postSaveOrUpdateUser() {
				this.$apiFun.postSaveOrUpdateUser({
					id: this.ruleForm.id, //id 为空在添加，不为空则更新
					username: this.ruleForm.username, //登陆用户名
					password: this.ruleForm.password, //密码
					sex: this.ruleForm.sex, //0:男性 1：女性
					type: this.ruleForm.type, //0:班主任 1：管理员
					note: this.ruleForm.note, //备注
					active: this.ruleForm.active, //1 激活状态  0：冻结状态不能登陆
					name: this.ruleForm.name, //真实姓名
					phone: this.ruleForm.phone //手机号
				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						this.$message.success("操作成功");
						this.dialogVisible = false
						this.currentPage = 1
						this.getUserList()
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			getUserList() {
				this.$apiFun.getUserList({
					name: '',
					pageSize: this.pagesize,
					pageNum: this.currentPage
				}).then(res => { //eslint-disable-line no-unused-vars
					this.tableData = res.data.list
					this.total =res.data.total
				}).catch(error => {
					alert(error)
				})
			},
			deleteUser() {
				console.log(this.row_index)
				this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$apiFun.postDeleteUser('/admin/deleteUser/' + this.tableData[this.row_index].id, {}).then(
						res => { //eslint-disable-line no-unused-vars
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: '删除成功!'
								});
								this.getUserList()
							} else {
								this.$message.error(res.msg);
							}
						})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				row.row_index = rowIndex;
			},
			onRowClick(row) {
				this.row_index = row.row_index
			},
			tapUpdate(val) {
				// this.$refs['ruleForm']?.resetFields();
				this.dialogVisible = true
				this.$nextTick(()=>{
					if(val){
					for (const key in this.ruleForm) {
							this.ruleForm[key]= val[key];
					}
				}
				})
				
				
				// this.$apiFun.postSaveOrUpdateUser({ //id 为空在添加，不为空则更新
				// 	username: this.tableData[this.row_index].username, //登陆用户名
				// 	password: this.tableData[this.row_index].password, //密码
				// 	sex: this.tableData[this.row_index].sex, //0:男性 1：女性
				// 	type: this.tableData[this.row_index], //0:班主任 1：管理员
				// 	note: this.tableData[this.row_index], //备注
				// 	active: this.tableData[this.row_index], //1 激活状态  0：冻结状态不能登陆
				// 	name: this.tableData[this.row_index], //真实姓名
				// 	phone: this.tableData[this.row_index] //手机号
				// }).then(res => { //eslint-disable-line no-unused-vars
				// 	if (res.code == 200) {
				// 		this.$message.success("操作成功");
				// 		this.dialogVisible = false
				// 		this.getUserList()
				// 	} else {
				// 		this.$message.error(res.msg);
				// 	}
				// })
			}
		}

	}
</script>

<style scoped>
	/deep/ .el-tabs__header {
		margin: 0;
	}
	.el-pagination {
		width: 100%;
		background-color: #ffffff;
		padding: 15px 0;
		display: flex;
		justify-content: flex-end;
		margin-right: 40px;
	}

	.box {
		background-color: #ffffff;
		padding: 10px;
	}

	.content {
		width: 100%;
		padding: 15px;
		background-color: #ededed;
	}
</style>
