<template>
	<div class="content">
		<!-- 		<div class="box">
			<div class="box-title">催到课(小白课)专属链接</div>
			<div class="box-btn">
				<div class="box-btn-item" @click="nihao()">
					<div>对私链接：</div>
					<div>www.</div>
					<div>
						<el-button size="mini">复制</el-button>
					</div>
				</div>
				<div class="box-btn-item">
					<div>对私链接：</div>
					<div>www.</div>
					<div>
						<el-button size="mini">复制</el-button>
					</div>
				</div>
			</div>
		</div> -->
		<!-- 	<div class="box">
			<div class="box-title">学籍注册（小白营）专属链接</div>
			<div class="box-btn">
				<div class="box-btn-item">
					<div>企微自动回复链接：</div>
					<div>www.</div>
					<div>
						<el-button size="mini">复制</el-button>
					</div>
				</div>
				<div class="box-btn-item">
					<div>班主任追学籍链接：</div>
					<div>www.</div>
					<div>
						<el-button size="mini">复制</el-button>
					</div>
				</div>
			</div>
		</div> -->
		<div class="box">
			<div class="box-title">支付链接</div>
			<div class="rad" style="margin-top: 20px;">
				<div class="rad-odiv">
					<div class="rad-odiv-text"><span>*</span> 选择商品:</div>
					<el-radio v-model="obj.radio" label="1">短视频变现2980</el-radio>
					<el-radio v-model="obj.radio" label="2">短视频变现1980</el-radio>
					<el-radio v-model="obj.radio" label="3">短视频变现2580</el-radio>
					<el-radio v-model="obj.radio" label="4">短视频变现5160</el-radio>
				</div>
			</div>
			<div class="rad" style="margin-top: 36px;">
				<div class="rad-odiv">
					<div class="rad-odiv-text"><span>*</span> 收款选项:</div>
					<el-radio v-model="obj.radio1" label="1">专属定金</el-radio>
					<el-radio v-model="obj.radio1" label="2">专属尾款</el-radio>
					<el-radio v-model="obj.radio1" label="3">全款</el-radio>
					<el-radio v-model="obj.radio1" label="4">固定定金</el-radio>
				</div>
			</div>
			<div class="rad" v-show="top1==1" style="margin-top: 25px;">
				<div class="rad-odiv" style="display: flex;align-items: center;">
					<div class="rad-odiv-text"><span>*</span> 收款学员uid:</div>
					<el-input v-model="obj.input" placeholder="请输入uid"></el-input>
					<!-- <el-button class="rad-butt" size="mini">获取已付明细</el-button> -->
				</div>
			</div>
			<div class="rad">
				<div class="rad-odiv" style="display: flex;align-items: center;margin-top: 10px;">
					<div class="rad-odiv-text"><span>*</span> 收款金额:</div>
					<div v-show="top1==1||top1==2">
						<el-input @input="tapFocus()" :disabled=disabled1 v-model="obj.input1" placeholder="请输入收款金额">
						</el-input>
						<!-- <el-button class="rad-butt" size="mini">获取价格提醒</el-button> -->
					</div>
					<div v-show="top1==3">
						<el-radio v-for="(item,index) in this.money[this.obj.radio-1]" :key="index" v-model="radio2"
							:label="item">
							{{item}}
						</el-radio>
					</div>
				</div>
			</div>
			<div class="rad radurl" v-show="!(JSON.stringify(target)==='{}')">
				<div class="rad-odiv" style="display: flex;align-items: center;">
					<div class="rad-odiv-text"><span>*</span>支付链接</div>
					<div style="width: auto;">{{target.linkUrl}}</div>
					<el-button type="primary" v-clipboard:copy="target.linkUrl" v-clipboard:success="onCopySuccess"
						v-clipboard:error="onCopyError" size="mini">复制</el-button>
				</div>
				<img :src="target.qrImage" />
			</div>
			<div class="rad">
				<div class="rad-odiv" style="margin-left: 16px;">
					<el-button :disabled="disabled" class="rad-butt" size="mini" @click="tapGain()">获取支付链接</el-button>
					<el-button class="" size="mini" @click="tapResetting()">重置</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LinkManagement',
		props: {
			msg: String
		},
		data() {
			return {
				target: {},
				obj: {
					radio: '',
					radio1: '',
					input: '',
					input1: ''
				},
				radio2: '',
				top1: 1,
				money: [
					[500],
					[280, 580, 980],
					[100, 300, 500, 1000, 1380],
					[0]
				],
				disabled: true,
				disabled1: false,
				link: ''
			}
		},
		computed: {
			'newParams': function() {
				const {
					radio,
					radio1,
					input,
					input1
				} = this.obj
				return {
					radio,
					radio1,
					input,
					input1
				}
			}
		},
		methods: {
			tapFocus() {
				if (this.obj.radio1 != 3 && this.obj.radio1 != 4) {
					console.log(11)
					this.link = this.obj.input1
				}
			},
			nihao() {
				this.$router.push("/manage");
			},
			onCopySuccess() {
				this.$message.success("复制成功");
			},
			onCopyError() {
				this.$message.error("复制失败");
			},
			tapResetting() {
				this.obj = {}
				this.disabled = true
			},
			tapGain() {
				this.$apiFun.postAddUserLink({
					uid: this.obj.input, //要绑定的客户uid
					teacherId: this.$store.getters.getId, //班主任用户id
					payType: this.obj.radio1, //支付类型
					orderType: this.obj.radio, //订单类型
					money: parseInt(this.link)
				}).then(res => { //eslint-disable-line no-unused-vars
					this.target = res.data
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '获取成功!'
						});
					}
				}).catch(error => {
					this.$message.error(error.msg);
				})
			}
		},
		watch: {
			newParams: function() {
				if (this.obj.radio1 == 1 || this.obj.radio1 == 2) {
					if (this.obj.input.length > 0 && this.obj.input1.length > 0 && this.obj.radio1.length > 0 && this
						.obj.radio.length > 0) {
						this.disabled = false
					} else {
						this.disabled = true
					}
				}
			},
			radio2() {
				this.link = this.radio2
				if (this.radio2 > -1) {
					this.disabled = false
				} else {
					this.disabled = true
				}
			},
			'obj.radio': {
				handler() {
					if (this.obj.radio1 == 3) {
						if (this.obj.radio == 1) {
							this.obj.input1 = 2980
							this.link = 2980
						}
						if (this.obj.radio == 2) {
							this.obj.input1 = 1980
							this.link = 1980
						}
						if (this.obj.radio == 3) {
							this.obj.input1 = 2580
							this.link = 2580
						}
						if (this.obj.radio == 4) {
							this.obj.input1 = 5160
							this.link = 5160

							console.log(1111)
						}
						this.disabled = false
					}
					this.radio2 = ''
				}
			},
			'obj.radio1': {
				handler() {
					switch (parseInt(this.obj.radio1)) {
						case 1:
							this.top1 = 1
							this.disabled1 = false
							this.obj.input1 = ''
							this.obj.input = ''
							break;
						case 2:
							this.disabled1 = false
							this.obj.input1 = ''
							this.obj.input = ''
							this.top1 = 1
							break;
						case 3:
							if (this.obj.radio.length > 0) {
								console.log(this.obj.radio)
								if (this.obj.radio == 1) {
									this.obj.input1 = 2980
									this.link = 2980
								}


								if (this.obj.radio == 2) {
									this.obj.input1 = 1980
									this.link = 1980
								}

								if (this.obj.radio == 3) {
									this.obj.input1 = 2580
									this.link = 2580
								}

								if (this.obj.radio == 4) {
									this.obj.input1 = 5160
									this.link = 5160
								}

								this.disabled = false
							}
							this.disabled1 = true
							this.top1 = 2
							break;
						case 4:
							this.disabled = true
							this.top1 = 3
							break;
						default:
							break;
					}
				}
			}
		}
	}
</script>


<style scoped>
	.radurl {
		padding: 0 !important;
		margin-top: 6px;
	}

	.rad img {
		width: 140px;
		height: 140px;
		margin-left: 84px;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.rad-odiv-text {
		display: flex;
		justify-content: flex-end;
	}

	.rad-butt {
		margin-left: 76px;
	}

	/deep/.rad .el-button--mini {
		padding: 8px 15px !important;
	}

	/deep/.el-input__inner {
		width: 160px !important;
		font-size: 12px !important;
		line-height: 30px !important;
		height: 30px !important;
	}

	.rad {
		margin-left: 68px;
		padding-bottom: 20px;
	}

	.rad-odiv div span {
		color: red;
	}

	.el-radio {
		margin-right: 18px !important;
	}

	/deep/.el-radio__label {
		font-size: 12px !important;
	}

	.rad-odiv div {
		width: 80px;
		line-height: 13px;
		margin-right: 12px;
		text-align: center;
		display: flex;
	}

	.rad-odiv {
		display: flex;
		font-size: 12px;
		color: rgba(0, 0, 0, .85);
	}

	.box-btn-item div:nth-of-type(3) {
		margin-left: 20px;
	}

	.box-btn-item {
		display: flex;
		align-items: center;
		padding: 12px;
	}

	.box-btn {
		color: rgba(0, 0, 0, .65);
		font-size: 12px;
	}

	.box-title {
		width: 100%;
		display: inline-block;
		flex: 1;
		padding: 16px 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-left: 24px;
		border-bottom: 1px solid #dedede;
		font-size: 14px;
	}

	.box {
		border: 1px solid #dedede;
		background-color: #ffffff;
		margin-bottom: 10px;
	}

	.content {
		width: 100%;
		padding: 15px;
		background-color: #ededed;
	}
</style>
