<template>

	<div class="odiv-content">
		<div style="height:20px;"></div>
		<div class="content">
			<div class="box">
				<div class="box-item">
					<div class="box-item-text">链接：</div>
					<div class="box-ipt">
						<el-input v-model="obj.linkId" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="box-item">
					<div class="box-item-text" style="width: 84px;">昵称：</div>
					<div class="box-ipt">
						<el-input v-model="obj.nickname" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="block">
					<div class="demonstration box-item-text">时间：</div>
					<el-date-picker @change="tapClck()" value-format="yyyy-MM-dd HH:mm:ss" v-model="time"
						type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="box-item">
					<div class="box-item-text">订单号：</div>
					<div class="box-ipt">
						<el-input v-model="obj.orderNumber" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="box-item-text">支付类型：</div>
				<el-select @change="tapSele1($event)" v-model="obj.payType" placeholder="请选择">
					<el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<div class="box-item-text">支付状态：</div>
				<el-select @change="tapSele2($event)" v-model="obj.payStatus" placeholder="请选择">
					<el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<div style="margin-left: 74px;">
					<el-button @click="tapGetUserList(1)" type="primary">查询</el-button>
					<el-button @click="tapGetUserList(2)" type="primary">重置</el-button>
					<el-button @click="tapExport()" type="primary">导出</el-button>
				</div>
			</div>

			<el-table :row-class-name="tableRowClassName" @row-click="onRowClick" :data="gridData" :stripe="true"
				height="69vh" :border="true">
				<el-table-column property="" label="头像">
					<template slot-scope="scope">
						<img v-if="scope.row.payType==2" class="userImg" src="../assets/icon.png" />
						<img v-else class="userImg" :src="scope.row.headUrl" />
					</template>
				</el-table-column>
				<el-table-column property="userPhone" label="手机号"></el-table-column>
				<el-table-column property="userName" label="昵称"></el-table-column>
				<el-table-column property="ordernumber" label="订单号"></el-table-column>
				<el-table-column property="timesClickIndex" label="点击报名">
				</el-table-column>
				<el-table-column property="timesClickCashier" label="待支付">
				</el-table-column>
				<el-table-column property="timesClickPay" label="点击支付">
				</el-table-column>
				<!-- <el-table-column property="userUid" label="uid"></el-table-column> -->
				<el-table-column property="createTime" width="160" label="创建时间"></el-table-column>
				<el-table-column property="payTime" width="160" label="支付时间"></el-table-column>
				<el-table-column property="payType" label="支付类型" :formatter="appPayType"></el-table-column>
				<el-table-column property="money" label="支付金额"></el-table-column>
				<el-table-column property="status" label="是否支付" :formatter="appStatus"></el-table-column>
			</el-table>
			<div class="el-pagination">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize"
					:current-page.sync="currentPage" background layout="prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
		<div style="height:20px;"></div>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		data() {
			return {
				options1: [{
						value: 2,
						label: '微信'
					},
					{
						value: 3,
						label: '支付宝'
					}
				],
				options2: [{
						value: 0,
						label: '未支付'
					},
					{
						value: 1,
						label: '已支付'
					}, {
						value: 3,
						label: '支付中'
					},
					{
						value: 4,
						label: '支付失败'
					}
				],
				value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
				value2: '',
				gridData: [],
				currentPage: 1, //初始页
				pagesize: 20, //    每页的数据
				total: 0, //    总数
				userList: [],
				row_index: '',
				tableData: [],
				dialogVisible: false,
				input: '',
				input1: '',
				id: '',
				nickname: '',
				obj: {
					linkId: '',
					nickname: '',
					startTime: '',
					endTime: '',
					payType: '',
					orderNumber: '',
					payStatus: ''
				},
				time: ''
			}
		},
		created() {
			this.id = this.$route.query.id
			this.getUserList()
		},
		methods: {
			tapExport() {
				this.$apiFun.getExportPayList({
					linkId: this.obj.linkId,
					nickname: this.obj.nickname,
					startTime: this.obj.startTime,
					endTime: this.obj.endTime,
					payType: this.obj.payType,
					orderNumber: this.obj.orderNumber,
					payStatus: this.obj.payStatus
				}).then(res => { //eslint-disable-line no-unused-vars
					console.log(res, 'resss');
					const blob = new Blob([res], {
						type: "application/vnd.ms-excel;charset=UTF-8"
					});
					let fileName = '下载.xlsx'
					let a = document.createElement('a')
					let url = window.URL.createObjectURL(blob)
					a.href = url
					a.download = fileName
					document.body.appendChild(a)
					a.style.display = 'none'
					a.click()
					document.body.removeChild(a)
					window.URL.revokeObjectURL(url)
				})
			},
			tapGetUserList(e) {
				if (e == 2) {
					this.obj = {
						linkId: '',
						nickname: '',
						startTime: '',
						endTime: '',
						payType: '',
						orderNumber: '',
						payStatus: ''
					}
				}
				this.getUserList()
			},
			tapSele1(e) {
				this.payType = e
			},
			tapSele2(e) {
				this.payStatus = e
			},
			tapClck() {
				this.obj.startTime = this.time[0]
				this.obj.endTime = this.time[1]
			},
			tapNickname() { // 微信
				if (this.input1.length > 0) {
					this.id = this.input1
					this.getUserList()
				} else {
					this.$message.error({
						message: '输入不能为空!',
						duration: 500
					});
				}
			},
			taplinkId() { // 连接
				if (this.input.length > 0) {
					this.nickname = ''
					this.id = this.input
					this.getUserList()
				} else {
					this.$message.error({
						message: '输入不能为空!',
						duration: 500
					});
				}
			},
			tapId() {
				this.id = ''
				this.nickname = ''
				this.getUserList()
			},
			appPayType(row, column) {
				let value = row[column.property];
				if (value == "2") return "支付宝";
				else if (value == "3") return "微信";
				else return "";
			},
			appStatus(row, column) {
				let value = row[column.property];
				if (value == "0") return "未支付";
				else if (value == "1") return "已支付";
				else if (value == "3") return "支付中";
				else if (value == "4") return "支付失败";
				else return "";
			},
			tapResetting() {
				this.$router.push("/index/userClickList");
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getUserList()
			},
			appCreateTime(row) {
				return row.createTime.substring(0, 10)
			},
			appActive(row) {
				return row.status == -1 ? '已删除' : '正常'
			},
			appType(row, column) {
				return this.formatDate(row[column.property] * 1000)
			},
			appSex(row, column) {
				let value = row[column.property];
				if (value == "0") return "男";
				else if (value == "1") return "女";
				else return "";
			},
			submitForm(formName) {
				console.log(this.ruleForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.postSaveOrUpdateUser()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			formatDate(date, fmt) {
				date = new Date(date)
				if (typeof fmt === 'undefined') {
					fmt = 'yyyy-MM-dd HH:mm:ss'
				}
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
				}
				const o = {
					Y: date.getFullYear(),
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'H+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds(),
				}
				for (const k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						const str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
					}
				}
				return fmt
			},
			getUserList() {
				this.$apiFun.getPayList({
					pageSize: this.pagesize,
					pageNum: this.currentPage,
					linkId: this.obj.linkId,
					nickname: this.obj.nickname,
					startTime: this.obj.startTime,
					endTime: this.obj.endTime,
					payType: this.obj.payType,
					orderNumber: this.obj.orderNumber,
					payStatus: this.obj.payStatus
				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						this.gridData = res.data.list
						this.total = res.data.total
						this.$message.success({
							message: '操作成功!',
							duration: 500
						});
					} else {
						this.$message.error(res.msg);
					}
				})
				// this.$apiFun.getCourseList({
				// 	startTime: "",
				// 	endTime: "",
				// 	pageSize: this.pagesize,
				// 	pageNum: this.currentPage
				// }).then(res => { //eslint-disable-line no-unused-vars
				// 	this.tableData = res.data
				// 	this.total = res.total
				// }).catch(error => {
				// 	alert(error)
				// })
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				row.row_index = rowIndex;
			},
			onRowClick(row) {
				this.row_index = row.row_index
			},
			tapUpdate() {
				// this.$apiFun.postSaveOrUpdateUser({ //id 为空在添加，不为空则更新
				// 	username: this.tableData[this.row_index].username, //登陆用户名
				// 	password: this.tableData[this.row_index].password, //密码
				// 	sex: this.tableData[this.row_index].sex, //0:男性 1：女性
				// 	type: this.tableData[this.row_index], //0:班主任 1：管理员
				// 	note: this.tableData[this.row_index], //备注
				// 	active: this.tableData[this.row_index], //1 激活状态  0：冻结状态不能登陆
				// 	name: this.tableData[this.row_index], //真实姓名
				// 	phone: this.tableData[this.row_index] //手机号
				// }).then(res => { //eslint-disable-line no-unused-vars
				// 	if (res.code == 200) {
				// 		this.$message.success("操作成功");
				// 		this.dialogVisible = false
				// 		this.getUserList()
				// 	} else {
				// 		this.$message.error(res.msg);
				// 	}
				// })
			}
		}

	}
</script>

<style>
	.block {
		display: flex;
		align-items: center;
	}

	.box-ipt .el-input__inner {
		width: 107%;
	}
</style>
<style scoped>
	.box-item-text {
		width: 70px;
		margin-right: 4px;
		text-align: end;
		color: #606266;
		font-size: 14px;
		margin: 20px 0;
		margin-left: 60px;
	}

	.box-item {
		display: flex;
		align-items: center;
		color: #606266;
		font-size: 14px;
	}

	.box {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background-color: #ffffff;
		padding: 20px;
	}

	.userImg {
		width: 50px;
		height: 50px;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	.el-pagination {
		width: 100%;
		background-color: #ffffff;
		padding: 15px 0;
		display: flex;
		justify-content: flex-end;
		margin-right: 40px;
	}

	.odiv-content {
		width: 90%;
		background-color: rgb(237, 237, 237);

	}

	.content {
		width: 98%;
		margin: 0 auto;
		background-color: #ededed;

	}
</style>