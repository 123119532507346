import {
	post,
	get
} from '../request/request.js'

let apiFun = {};
apiFun.postLogin = p => post('/login', p)
apiFun.getUserList = p => get('/admin/userList', p)
apiFun.getLinkList = p => get('/admin/userLinkList', p)
apiFun.getCourseList = p => get('/admin/courseList', p)
apiFun.getClickList = p => get('/admin/userClickList', p)
apiFun.postAddUserLink = p => post('/admin/addUserLink', p)
apiFun.postSaveOrUpdateUser = p => post('/admin/saveOrUpdateUser', p)
apiFun.getOnLineStudentList = p => get('/admin/onLineStudentList', p)
apiFun.getOutLineStudentListt = p => get('/admin/outLineStudentList', p)
apiFun.postAddAdminLink = p => post('/admin/addAdminLink', p)
apiFun.postDeleteUser = p => post(p)
apiFun.getPayList = p => get('/admin/getPayList', p)
apiFun.getFixedLinkDetail = p => get('/admin/getFixedLinkDetail', p)
apiFun.getExportPayList = p => get('/admin/exportPayList', p, {responseType: 'blob'})
apiFun.getUIpload = p => post('/admin/upload', p,{headers: {
  'Content-Type': 'multipart/form-data', //设置请求头请求格式form
}
})

apiFun.uploadQrcode = p => post('/admin/uploadQrcode', p,{headers: {
  'Content-Type': 'multipart/form-data', //设置请求头请求格式form
}
})
apiFun.getAllQrcode = p => get('/admin/getAllQrcode', p)
apiFun.postDeleteQrcode = p => post('/admin/deleteQrcode', p)
apiFun.postSaveQrcode = p => post('/admin/saveQrcode', p)
apiFun.postAddRegistorStatus = p => post('/admin/addRegistorStatus', p)
export default apiFun;