<template>
	<div class="content">
		<div class="box">
			<div class="box-title">支付链接</div>

			<div class="rad">
				<div class="rad-odiv" style="display: flex;align-items: center;margin-top: 10px;">
					<div class="rad-odiv-text"><span>*</span> 收款金额</div>
					<el-input @input="tapFocus()" :disabled=disabled1 v-model="obj.input1" placeholder="请输入收款金额">
					</el-input>
				</div>
			</div>
			<div class="rad" style="margin-top: 36px;">
				<div class="rad-odiv">
					<div class="rad-odiv-text"><span>*</span> 收款选项:</div>
					<el-radio v-model="obj.radio1" label="1">定金</el-radio>
					<el-radio v-model="obj.radio1" label="2">全额</el-radio>
					<el-radio v-model="obj.radio1" label="3">应急产品</el-radio>
					<el-radio v-model="obj.radio1" label="4">常规产品</el-radio>
				</div>
			</div>
			<div class="rad radurl" v-show="shop">
				<div class="rad-odiv" style="display: flex;align-items: center;">
					<div class="rad-odiv-text"><span>*</span>支付链接</div>
					<div style="width: auto;">{{target.linkUrl}}</div>
					<el-button type="primary" v-clipboard:copy="target.linkUrl" v-clipboard:success="onCopySuccess"
						v-clipboard:error="onCopyError" size="mini">复制</el-button>
				</div>
				<img :src="target.qrImage" />
			</div>
			<div class="rad">
				<div class="rad-odiv" style="margin-left: 16px;">
					<el-button :disabled="disabled" class="rad-butt" size="mini" @click="tapGain()">获取支付链接</el-button>
					<el-button class="" size="mini" @click="tapResetting()">重置</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LinkManagement',
		props: {
			msg: String
		},
		data() {
			return {
				shop: false,
				target: {
					qrImage: ''
				},
				obj: {
					radio: '3',
					radio1: '',
					input: '',
					input1: ''
				},
				radio2: '',
				top1: 1,
				disabled: true,
				disabled1: false
			}
		},
		computed: {
			'newParams': function() {
				const {
					radio,
					radio1,
					input,
					input1
				} = this.obj
				return {
					radio,
					radio1,
					input,
					input1
				}
			}
		},
		mounted() {
			// this.$apiFun.getFixedLinkDetail({ //要绑定的客户uid

			// }).then(res => { //eslint-disable-line no-unused-vars
			// 	this.target.linkUrl = res.data.linkUrl
			// 	this.target.qrImage = res.data.qrCode

			// 	console.log(res.data.linkUrl)
			// 	console.log(res.data.qrCode)
			// 	console.log(this.target.linkUrl)
			// 	console.log(this.target.qrImage)
			// })
		},
		methods: {
			tapFocus() {
				console.log(this.obj.input1.length)
				console.log(this.obj.radio1.length)
				if (this.obj.input1.length > 0 && this.obj.radio1.length > 0) {
					this.disabled = false
				} else {
					this.disabled = true
				}
			},
			nihao() {
				this.$router.push("/manage");
			},
			onCopySuccess() {
				this.$message.success("复制成功");
			},
			onCopyError() {
				this.$message.error("复制失败");
			},
			tapResetting() {
				console.log(this.obj.radio1)
				this.obj = {}
				this.disabled = true
			},
			tapGain() {
				this.$apiFun.postAddAdminLink({ //要绑定的客户uid
					teacherId: this.$store.getters.getId, //班主任用户id
					money: this.obj.input1,
					payType: this.obj.radio1
				}).then(res => { //eslint-disable-line no-unused-vars
					this.target = res.data
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '获取成功!'
						});
						this.shop = true
					}
				}).catch(error => {
					this.$message.error(error.msg);
				})
			}
		},
		watch: {
			'obj.radio1': {
				handler() {
					console.log(this.obj.input1.length)
					console.log(this.obj.radio1.length)
					if (this.obj.radio1.length > 0 && this.obj.input1.length > 0) {
						this.disabled = false
					}
				}
			}
		}
	}
</script>


<style scoped>
	.radurl {
		padding: 0 !important;
		margin-top: 6px;

	}

	.rad img {
		width: 140px;
		height: 140px;
		margin-left: 84px;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.rad-odiv-text {
		display: flex;
		justify-content: flex-end;
	}

	.rad-butt {
		margin-left: 76px;
	}

	/deep/.rad .el-button--mini {
		padding: 8px 15px !important;
	}

	/deep/.el-input__inner {
		width: 160px !important;
		font-size: 12px !important;
		line-height: 30px !important;
		height: 30px !important;
	}

	.rad {
		margin-left: 68px;
		padding-bottom: 20px;
	}

	.rad-odiv div span {
		color: red;
	}

	.el-radio {
		margin-right: 18px !important;
	}

	/deep/.el-radio__label {
		font-size: 12px !important;
	}

	.rad-odiv div {
		width: 80px;
		line-height: 13px;
		margin-right: 12px;
		text-align: center;
		display: flex;
	}

	.rad-odiv {
		display: flex;
		font-size: 12px;
		color: rgba(0, 0, 0, .85);
	}

	.box-btn-item div:nth-of-type(3) {
		margin-left: 20px;
	}

	.box-btn-item {
		display: flex;
		align-items: center;
		padding: 12px;
	}

	.box-btn {
		color: rgba(0, 0, 0, .65);
		font-size: 12px;
	}

	.box-title {
		width: 100%;
		display: inline-block;
		flex: 1;
		padding: 16px 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-left: 24px;
		border-bottom: 1px solid #dedede;
		font-size: 14px;
	}

	.box {
		border: 1px solid #dedede;
		background-color: #ffffff;
		margin-bottom: 10px;
	}

	.content {
		width: 100%;
		padding: 15px;
		background-color: #ededed;
	}
</style>
