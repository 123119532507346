import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
// 用Vuex.Store对象用来记录token
const store = new Vuex.Store({

	state: {
		// 存储token
		token: "",
		userName: "", // 可选
		id: "",
		type:""
	},
	getters: {
		getToken(state) {
			return state.token || localStorage.getItem("token") || "";
		},
		getId(state) {
			return state.id || localStorage.getItem("id") || "";
		},
		getType(state) {
			return state.type || localStorage.getItem("type") || "";
		}
	},
	mutations: {
		// 修改token，并将token存入localStorage
		setToken(state, token) {
			state.token = token;
			localStorage.setItem('token', token);
			console.log('store、localstorage保存token成功！');
		},
		setId(state, id) {
			state.id = id;
			localStorage.setItem('id', id);
			console.log('store、localstorage保存id成功！');
		},
		setType(state, type) {
			state.type = type;
			localStorage.setItem('type', type);
			console.log('store、localstorage保存type成功！');
		},
		delType(state){
			state.type = "";
			localStorage.removeItem("type");
		},
		delID(state) {
			state.id = "";
			localStorage.removeItem("id");
		},
		delToken(state) {
			state.token = "";
			localStorage.removeItem("token");
		}
	},

	actions: {
		// removeToken: (context) => {
		// context.commit('setToken')
		// }
	},
});

export default store;
