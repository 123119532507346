import Vue from 'vue';
import {
	Button
} from 'element-ui';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';
import apiFun from './api/api.js';
import VueClipboard from 'vue-clipboard2'    

Vue.use(VueClipboard)    
 
Vue.prototype.$apiFun = apiFun;
Vue.component(Button.name, Button);
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
