<template>
	<div class="content">
		<div style="background-color: #fff;">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="全部" name="fourth"></el-tab-pane>
				<el-tab-pane label="点击报名" name="first"></el-tab-pane>
				<el-tab-pane label="点击支付" name="second"></el-tab-pane>
				<el-tab-pane label="未点击" name="third"></el-tab-pane>
				<el-table :row-class-name="tableRowClassName" @row-click="onRowClick" :data="tableData" :stripe="true"
					height="80vh" :border="true">
					<!-- <el-table-column property="" label="头像">
						<template slot-scope="scope">
							<img class="userImg" :src="scope.row.headUrl" />
						</template>
					</el-table-column> -->
					<el-table-column prop="teacherName" label="班主任姓名">
					</el-table-column>
					<el-table-column prop="linkUrl" label="链接">
					</el-table-column>
					<el-table-column prop="type" label="类型" :formatter="appType">
					</el-table-column>
					<el-table-column prop="userUid" label="学员uid" :formatter="appSex">
					</el-table-column>
					<el-table-column prop="userName" label="学员姓名">
					</el-table-column>
					<el-table-column prop="userPhone" label="学员手机号">
					</el-table-column>
					<el-table-column prop="money" label="金额">
					</el-table-column>
					<!-- 	<el-table-column prop="payStatus" label="支付状态" :formatter="appPayStatus">
					</el-table-column>
					<el-table-column prop="payTime" label="支付时间">
					</el-table-column> -->
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="tapPayList(scope.row)" type="primary">查看支付详情</el-button>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="createTime" label="创建时间" :formatter="appCreateTime">
					</el-table-column> -->
				</el-table>
			</el-tabs>
		</div>

		<div class="el-pagination">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" :current-page.sync="currentPage"
				background layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		data() {
			return {
				currentPage: 1, //初始页,
				pagesize: 20, //    每页的数据 //  
				total: 0, //    总数
				userList: [],
				row_index: '',
				tableData: [],
				dialogVisible: false,
				ruleForm: {
					id: '',
					username: '',
					password: '',
					sex: '',
					type: '',
					note: '',
					active: '',
					name: '',
					phone: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请至少选择一个账户类别',
						trigger: 'change'
					}]
				},
				activeName: 'fourth',
				payStatus: '',
				gridData: [],
				dialogTableVisible: false,
				dialogFormVisible: false,
				linkId: ''
			}
		},
		created() {
			this.getUserList()
		},
		methods: {
			appPayType(row, column) {
				let value = row[column.property];
				if (value == "2") return "支付宝";
				else if (value == "3") return "微信";
				else return "";
			},
			appStatus(row, column) {
				let value = row[column.property];
				if (value == "0") return "未支付";
				else if (value == "1") return "已支付";
				else if (value == "4") return "支付失败";
				else return "";
			},
			tapPayList(val) {
				this.$router.push({
					path: '/getPayList',
					query: {
						id: val.linkId,
					}
				})

				// this.$apiFun.getPayList('/admin/getPayList/' + val.linkId, {
				// 	pageSize: 5,
				// 	pageNum: 1,
				// }).then(res => { //eslint-disable-line no-unused-vars
				// 	this.gridData = res.data.list
				// 	console.log(res.data.list)
				// })
				// this.dialogTableVisible = true
			},
			handleClick(tab, ) {
				this.payStatus = tab.index
				this.getUserList()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getUserList()
			},
			// appCreateTime(row) {
			// 	return row.createTime.substring(0, 10)
			// },
			appActive(row, column) {
				let value = row[column.property];
				if (value == "0") return "冻结";
				else if (value == "1") return "激活";
				else return "";
			},
			appPayStatus(row, column) {
				let value = row[column.property];
				if (value == "0") return "未点击";
				else if (value == "1") return "打开支付页面";
				else if (value == "2") return "点击支付";
				else if (value == "3") return "选择支付方式之后点击支付";
				else if (value == "4") return "支付失败";
				else if (value == "5") return "支付成功";
				else return "";
			},
			appType(row, column) {
				let value = row[column.property];
				if (value == "1") return "销售链接";
				else if (value == "2") return "直播间链接";
				else return "";
			},
			appSex(row, column) {
				let value = row[column.property];
				if (value == "0") return "男";
				else if (value == "1") return "女";
				else return "";
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.postSaveOrUpdateUser()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			postSaveOrUpdateUser() {
				this.$apiFun.postSaveOrUpdateUser({
					id: this.ruleForm.id, //id 为空在添加，不为空则更新
					username: this.ruleForm.username, //登陆用户名
					password: this.ruleForm.password, //密码
					sex: this.ruleForm.sex, //0:男性 1：女性
					type: this.ruleForm.type, //0:班主任 1：管理员
					note: this.ruleForm.note, //备注
					active: this.ruleForm.active, //1 激活状态  0：冻结状态不能登陆
					name: this.ruleForm.name, //真实姓名
					phone: this.ruleForm.phone //手机号
				}).then(res => { //eslint-disable-line no-unused-vars
					if (res.code == 200) {
						this.$message.success("操作成功");
						this.dialogVisible = false
						this.getUserList()
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			getUserList() {
				this.$apiFun.getClickList({
					teacherId: localStorage.getItem('type') == 1 ? '' : localStorage.getItem('id'),
					pageSize: this.pagesize,
					pageNum: this.currentPage,
					payStatus: this.payStatus
				}).then(res => { //eslint-disable-line no-unused-vars
					this.tableData = res.data.list
					this.total = res.data.total
				}).catch(error => {
					alert(error)
				})
			},
			deleteUser(val) {
				console.log(this.row_index)
				this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$apiFun.postDeleteUser('/admin/deleteUser/' + val.id, {}).then(
						res => { //eslint-disable-line no-unused-vars
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: '删除成功!'
								});
								this.getUserList()
							} else {
								this.$message.error(res.msg);
							}
						})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				row.row_index = rowIndex;
			},
			onRowClick(row) {
				this.row_index = row.row_index
			}
		}

	}
</script>

<style scoped>
	.userImg {
		width: 40px;
		height: 40px;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	.el-pagination {
		width: 100%;
		background-color: #ffffff;
		padding: 15px 0;
		display: flex;
		justify-content: flex-end;
		margin-right: 40px;
	}

	.box {
		background-color: #ffffff;

	}

	.content {
		width: 89%;
		padding: 15px;
		box-sizing: border-box;
		background-color: #ededed;
	}
</style>